<template>
  <div v-if="isPermission">
    <CCard>
      <CCardBody>
        <div class="row">
          <div class="col-2">
            <CButton id="serialNoBackButton" block color="light" @click="goback()" class="align-items-center">
              <span><i class="fi fi-rr-arrow-left mr-2" style="position: relative; top: 2px;"></i></span>{{ $t('back') }}
            </CButton>
          </div>
          <div class="col-8"></div>
          <div class="col-2" v-if="isExport">
            <CButton id="serialNoExportButton" color="info" block @click="exportSerialInventoryReport()">
              <img src="/img/file-excel.png" alt="Excel Icon" style="width: 16px; height: 16px; margin-right: 8px; vertical-align: middle; position: relative; top: -2px;" />{{ $t('export') }}
            </CButton>
          </div>
        </div>

        <div class="mt-3">
          <table style="width:100%">
            <tr class="p-2">
              <td>
                <b class="pr-3 font-weight-normal">
                  {{ $t('productName') }}
                </b>
                {{ name }}
              </td>
              <td>
                <b class="pr-3 font-weight-normal">{{ $t('unit') }}</b> {{ unitName }}
              </td>
            </tr>
            <tr>
              <td>
                <b class="pr-3 font-weight-normal">{{ $t('pluCode') }}</b> {{ PLUCode }}
              </td>
              <td>
                <b class="pr-3 font-weight-normal">{{ $t('price') }}</b> {{ SKUPrice }} บาท
              </td>
            </tr>
            <tr>
              <td>
                <b class="pr-3 font-weight-normal">{{ $t('createdAt') }}</b> {{ createdAt }}
                <b class="pl-2 font-weight-normal"> {{ $t('by') }} </b>
                {{ createdBy }}
              </td>
              <td>
                <b class="pr-3 font-weight-normal">{{ $t('updatedAt') }}</b> {{ updatedAt }}
                <b class="pl-2 font-weight-normal"> {{ $t('by') }} </b>
                {{ updatedBy }}
              </td>
            </tr>
          </table>
        </div>
        <hr />
        <CDataTable :fields="fields" :items="items" border sorter >
          <template #status="{ item }">
            <td class="text-center">
              <CBadge
                v-if="item.status === $i18n.t('readyForSale')"
                color="success"
                class="font-weight-normal"
              >
                {{ item.status }}
              </CBadge>
              <CBadge v-else-if="item.status === $i18n.t('sold')" color="info" class="font-weight-normal">
                {{ item.status }}
              </CBadge>
              <CBadge
                v-else-if="item.status === $i18n.t('delete')"
                color="danger"
              >
                {{ item.status }}
              </CBadge>
            </td>
          </template>
          <template #no-items-view>
            <div class="text-center my-5">
              <h4 style="color: #ced2d8;">
                {{ $t('noItem') }}
              </h4>
            </div>
          </template>
        </CDataTable>
      </CCardBody>
    </CCard>
  </div>
  <div v-else>
    <access-data></access-data>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import axios from '@/services/service'
import report from '@/services/report'
import permis from '@/util/permission'

export default {
  data() {
    return {
      data: [],
      name: '',
      unitName: '',
      PLUCode: '',
      SKUPrice: '',
      createdAt: '',
      createdBy: '',
      updatedAt: '',
      updatedBy: '',
      list: [],
    }
  },
  computed: {
    ...mapGetters(['shops', 'shopObjectId']),
    isPermission() {
      return permis.findPermissionRead('inventory', '/inventory/serial')
    },
    isExport() {
      return permis.findPermissionExport('inventory', '/inventory/serial')
    },
    objectId() {
      return this.$route.params.objectId
    },
    fields() {
      return [
        {
          key: 'id',
          label: this.$i18n.t('number'),
          _classes: 'text-center text-dark font-weight-normal',
          _style: 'width:5%',
        },
        {
          key: 'serialNumber',
          label: this.$i18n.t('productSN'),
          _style: 'width:30%',
          _classes: 'text-dark font-weight-normal'
        },
        {
          key: 'status',
          label: this.$i18n.t('status'),
          _style: 'width:10%',
          _classes: 'text-center text-dark font-weight-normal',
        },
        {
          key: 'createdAt',
          label: this.$i18n.t('createdAt'),
          _classes: 'text-dark font-weight-normal'
        },
        {
          key: 'createdBy',
          label: this.$i18n.t('createBy'),
          sorter: false,
          _style: 'width:10%',
          _classes: 'text-dark font-weight-normal'
        },
        {
          key: 'updatedAt',
          label: this.$i18n.t('updatedAt'),
          _classes: 'text-dark font-weight-normal'
        },
        {
          key: 'updatedBy',
          label: this.$i18n.t('updatedBy'),
          sorter: false,
          _style: 'width:10%',
          _classes: 'text-dark font-weight-normal'
        },
      ]
    },
    items() {
      let data = this.list
      let detail = []
      let createdAt = ''
      let createdBy = ''
      let updatedAt = ''
      let updatedBy = ''
      let status = ''

      for (let i = 0; i < data.length; i++) {
        createdBy = data[i].created_by.username || '-'
        createdAt = moment(data[i].created_at).format('DD/MM/YYYY HH:mm')
        updatedBy = data[i].updated_by.username || '-'
        updatedAt = moment(data[i].updated_at).format('DD/MM/YYYY HH:mm')

        if (data[i].deleted_at) {
          status = this.$i18n.t('delete')
        } else {
          if (data[i].serialStatus === 'P0') {
            status = this.$i18n.t('sold')
          } else if (data[i].serialStatus === 'P1') {
            status = this.$i18n.t('readyForSale')
          }
        }

        detail.push({
          id: i + 1,
          serialNumber: data[i].serialNumber || '-',
          status: status,
          createdAt: createdAt,
          createdBy: createdBy,
          updatedAt: updatedAt,
          updatedBy: updatedBy,
        })
      }
      return detail
    },
  },
  created() {
    this.getSerialDetail()
  },
  methods: {
    goback() {
      this.$router.push('/inventory/serial')
    },
    getSerialDetail(page = 1) {
      const uid = `${localStorage.getItem('shopsUid')}`

      const params = {
        shopObjectId: this.shopObjectId,
        pluObjectId: this.objectId,
      }

      axios({
        url: '/inventory/v1.0/' + uid + '/serial/detail',
        params: params,
        method: 'GET',
      })
        .then((res) => {
          let doc = res.data.data[0]
          this.data = doc
          this.name = doc.name
          this.unitName = doc.unit.name
          this.PLUCode = doc.PLUCode
          this.SKUPrice = doc.SKUPrice
          this.createdAt = moment(doc.created_at).format('LLL')
          this.createdBy = doc.created_by.username || '-'
          this.updatedAt = moment(doc.updated_at).format('LLL')
          this.updatedBy = doc.updated_by.username || '-'
          this.list = doc.list
        })
        .catch((error) => {
          console.log(error)
        })
    },
    exportSerialInventoryReport() {
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId
      const params = { shopObjectId: shopObjectId, pluObjectId: this.objectId }

      report({
        url: '/inventory/v1.0/' + uid + '/serial/detail/excel',
        params: params,
        method: 'GET',
        responseType: 'blob', // important
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', this.name + '.xlsx') //or any other extension
          document.body.appendChild(link)
          link.click()
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>
